$mainColor: #2b4570;  $secondColor: #7180ac; $hightlight: #a8d0db; $textColor: black; 
$coolColor: #1e93d6; $grey: #dcdcdc5d; $navColor: #171f36;
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;700&display=swap');

// HEADER =======================================================================================

// html {
//     height: 100vh;
// }

h1 {
    font-family: 'Roboto Slab', serif;
} 

h2 {
    font-family: 'Roboto Slab', serif;
}

body {
    font-family: charter, Georgia, Cambria, "Times New Roman", Times, serif;
    margin: 0;
    background-color: $grey;
    color: $textColor;
    // overflow: hidden;
}

.page-container {
    margin-top: 3rem;
}

a {
    color: $textColor;
    text-decoration: none;
}

.my-nav {
    z-index: 99;
    background-color: $navColor;
    box-shadow:2px 0px 30px 5px rgba(0, 0, 0, 0.664) ;
}

.navigation {
    display: flex;
    justify-content: space-evenly;
}

.inner-side {
 img {
     width: 100px;
 }
}

.menu-item {
    font-family: 'Roboto Slab', serif;
    width: 33%;
    text-align: center;
    margin: 0;
    padding: 1rem 0;
    // transform: none;
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
    transition: .2s ease;

    &:hover {
        color:rgba(154, 158, 158, 0.8);
    }
}

.menu-item.active {
    color:rgba(154, 158, 158, 0.8);
    pointer-events: none;
}

// END HEADER

// START MAINPAGE

.home {
    text-decoration: underline;
    cursor: pointer;
    transition: color .1s ease-in-out;

    &:hover {
        color: $coolColor;
    }
}

.page-container {
    position: relative;
}

.main-header {
    font-size: 2rem;
    text-align: center;
    margin-top: 1rem;
}

.socials {
    position: absolute;
    top: 0;
    right: 0;

    svg {
        transition: color .2s ease-in-out;
    }
    
    .github {
        &:hover {
            color: grey;
        }
    }

    .linkedin {
        &:hover {
            color: #0a66c2;
        }
    }
}

.responsive-img-box {
    text-align: center;
    padding-bottom: 2rem;

    div {
        height: unset;
        img {
            height: auto;
            max-width: 100%;
            max-height: 100%;
        }
    }
}

// END MAIN PAGE

// START ABOUT PAGE

.employment {
    position: relative;
}

.employment-history_tab {
    display: none;
    position: absolute;
    padding: 1rem;
    top: -1rem;
    right: 1rem;
    background-color:#dcdcdc5d;

    a {
        cursor: pointer;
    }
}

.nav-active {
    color: blue;
}

.employment-history {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border: 20px solid white;
    height: 20rem;
    padding: 1rem;
    background-color: white;
    overflow-y: scroll;
}

.employment-item {
    height: 100%;

    h3 {
        color: $mainColor;
    }

    p {
        max-width: 30rem;
        color: grey;
    }
}

// END ABOUT PAGE

// START WORK PAGE

.chip-container {
    display:flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 2rem;

    div {
        margin: .3rem .3rem;
    }
}

.web-card {
    position: relative;
    margin: 1rem 0 !important;

    &:hover {

		.caption {
            cursor: pointer;
			z-index: 10;
			position: absolute;
			display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
			bottom: 0;
			left: 0;
			background: #2b457094;
			width: 100%;

            h2 {
                text-decoration: underline;
            }
		  }
	}
}

.web-card::after {
	z-index: 1;
	position: absolute;
	content: "";
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: white;
	opacity: 0;
	transition: all .4s ease-in-out;
}

.web-card:hover::after {
	opacity: .8;
}

.caption {
	display: none;
  }
  
.caption span {
	color: #fff;
	font-family: Arial;
	display: block;
	font-size: .9rem;
	font-style: italic;
	padding: 1.5rem;
    position: absolute;
    bottom: 0;
}

.card-container {
    padding: 4rem;
}

.card-img {
    height: 200px;
    width: 100%;
}

.card-head {
    background-color: $navColor; 

    :nth-child(1) {
        color: white;
    }

    :nth-child(2) {
        color: $hightlight;
    }
}

.card-body {
    background-color: $secondColor;
    color: white;
}

.sorter {
    margin-bottom: 3rem;
}

// END WORK PAGE

// START CONTACT PAGE

.contact-h1 {
    font-size: 4.5rem;
    text-align: center;
    padding: 2rem 0;
}

.contact-center {
    padding: 0 2rem;
    font-size: 3rem;
    display: flex;
    justify-content: space-between;

    svg {
        transition: color .2s ease-in-out;
    }
    
    .github {
        &:hover {
            color: grey;
        }
    }

    .linkedin {
        &:hover {
            color: #0a66c2;
        }
    }

    .email {
        &:hover {
            color: grey;
        }
    }
}

// END CONTACT PAGE

// START MISC

@media(min-width: 40rem) {
    .page-container {
        margin-top: 0;
        margin-left: 6rem;
    }

    .my-nav {
         position: fixed;
         top: 0;
         height: 100vh;
         width: 6rem;
    }

    .navigation {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }


    .menu-item {
        width: 33%;
        display: block;
        margin: 0;
        text-align: center;
        transform: rotate(-90deg);
        font-size: 2rem;
        transition: .2s ease;
        padding: 0 2rem;
    }

    .main-header {
        font-size: 2rem;
        text-align: center;
        margin-top: 4rem;
    }

    .responsive-img-box {
        text-align: center;
        padding-bottom: 2rem;
    
        div {
            height: 55vh;
            img {
                height: auto;
                max-width: 100%;
                max-height: 100%;
            }
        }
    }

    .web-card {
        margin: 1rem !important;
    }

    .contact-h1 {
        font-size: 7.5rem;
        text-align: center;
        padding: 4rem 0;
    }

    .contact-center {
        padding: 0 2rem;
        font-size: 3rem;
        display: flex;
        justify-content: center;
        
        svg {
            margin: 0 2rem;
            cursor: pointer;
            transition: color .2s ease-in-out;
        }
    }

    .employment-history_tab {
        display: block;
        position: absolute;
        padding: 2rem;
        top:unset;
        right: 3rem;
        background-color:#dcdcdc5d;
    
        a {
            cursor: pointer;
        }
    }

    .left {
        top: 0;
    }

    .right {
        top: 0;
    }
}

// some random transition group css

.fade-enter {
    opacity: 0;
    z-index: 1;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 250ms ease-in;
}

.fade-exit {
    opacity: 0;
}

.pageSlider-exit  {
    z-index: 1;
    // display: none;
}

.pageSlider-enter  {
    z-index: 10;
}

.pageSlider-enter.left {
    transform: translate3d(100%, 0, 0);
}

.pageSlider-enter.right  {
    transform: translate3d(-100%, 0, 0);
}

.pageSlider-enter.pageSlider-enter-active  {
    transform: translate3d(0, 0, 0);
    transition: all .8s ease-in-out;
}

.pageSlider-exit {
    opacity: 0;
    // margin-top: 3rem;
    position: absolute;
    top: 4rem;
    width: 100vw;
    // transform: translate3d(0, 0, 0);
    transition: all .8s ease-in-out;
}

.scroll-bottom {
    z-index: 99;
    position: fixed;
    right: 0;
    bottom: 0;
    padding: 2rem .5rem;
    font-size: 1.4rem;
    color: red;
    cursor: pointer;
}